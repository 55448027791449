@import '../../Vars.css';

.thanks__container {
    position: relative;
    display: block;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    color: var(--lighttext);
    background-image: linear-gradient(190deg, var(--lightbackground1) 85%, var(--lightbackground2));
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.thanks__box {
    margin: auto;
    position: relative;
    font-style: normal;
}

.thanks__header {
    margin-bottom: 8px;
    font-size: 32px;
}

.thanks__contact {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 4px;
}

.thanks__contact img {
    margin-right: 8px;
    height: 24px;
    width: 24px;
}

@media (min-width: 64rem) {
    .thanks__header {
        margin-bottom: 8px;
        font-size: 64px;
    }

    .thanks__contact {
        font-size: 24px;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .thanks__contact img {
        margin-right: 12px;
        height: 36px;
        width: 36px;
    }
}

.about__content {
  width: 100vw;
  max-width: 100%;
  padding: 2rem;
  display: block;
  /* position: sticky; */
}

.about__content h2 {
  font-size: 2rem;
}

.about__content p {
  padding: 1rem 0;
  font-size: 1.2rem;
}

@media (min-width: 64rem) {
  .about__content {
    width: 70vw;
    padding: 6rem 4rem 6rem 10rem;
  }

  .about__content h2 {
    font-size: 2.2rem;
  }
  
  .about__content p {
    padding: 1rem 0;
    font-size: 1.4rem;
  }
}
.container {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: y proximity;
}

.section {
  scroll-snap-align: start;
}

.container::-webkit-scrollbar {
    display: none;
}
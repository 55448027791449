:root {
  --background1: #67666a;
  --background2: #1a1a1a;
  --text: #e5e5e5;
  --lightbackground1: #e5e5e5;
  --lightbackground2: #989995;
  --lighttext: #1a1a1a;
  --lighttext2: #757575;
  --accent0: #a5a5a5;
  --accent1: #5f84f6;
  --accent2: #d26e5d;
  --accent3: #fcdd31;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 10;
}

.link {
  margin: 0 -12px;
}

@import '../../../Vars.css';

.about__container {
  position: relative;
  display: block;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  color: var(--lighttext);
  background-color: var(--lightbackground1);
  /* background-image: linear-gradient(150deg, var(--lightbackground1), var(--lightbackground2)); */
}

.about__anim {
  margin: auto;
  width: 90vw;
  padding-bottom: 8rem;
}

@media (min-width: 64rem) {
  .about__container {
    display: flex;
  }

  .about__anim {
    margin: auto;
    margin-right: 5vw;
    max-width: 30vw;
    padding: 1rem;
  }
}

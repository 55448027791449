@import '../../../Vars.css';

.testimonial-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lighttext);
    background-color: var(--lightbackground1);
}

/* .testimonial-section:after {
    content: '';
    position: absolute;
    width: 900px;
    height: 550px;
    background: var(--lightbackground2);
    right: 0;
    bottom: 0;
    opacity: 0.5;
} */

.testimonial-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    padding: 30px 0;
}

.arrows {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 43vh;
    padding-left: 20px;
    bottom: 0;
    top: 0;
    /* cursor: pointer; */
    /* border-radius: 8px; */
    transition: 0.3s ease-in-out;
    z-index: 400;
}

/* .arrows:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 80, 0.05);
} */

.arrows.right {
    right: 20px;
}

.inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.t-image {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

/* .t-image:after {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    left: 20px; 
    top: 20px;
    border-radius: 100%;
    z-index: 10;
} */

.color-1:after {
    background: var(--accent2);
    opacity: 0.7;
}

.color-2:after {
    background: var(--accent3);
    opacity: 0.7;
}

.color-3:after {
    background: var(--accent1);
    opacity: 0.7;
}

.t-image ul {
    display: flex;
    position: absolute;
    overflow: hidden;
    height: 276px;
    width: 204px;
    box-shadow: 0px 0px 40px rgba(0, 0, 10, 0.25);
    z-index: 200;
}

/* .t-image ul li img { */
  .projectImage {
    height: 276px;
    width: 204px;
}

.t-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
}

.t-content ul {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 68vh;
}

.t-content ul li {
    width: 100%;
    height: 100%;
    padding: 0 5vw;
    top: 8vh;
    color: var(--lighttext);
    display: flex;
    align-items: top;
    position: absolute;
    opacity: 0;
}

/* .t-content ul li:nth-child(1):after {
    content: '';
    position: absolute;
    height: 10px;
    width: 186px;
    background: var(--accent2);
    opacity: 0.3;
    left: 36vw;
    top: 6.9vh;
}

.t-content ul li:nth-child(2):after {
    content: '';
    position: absolute;
    height: 10px;
    width: 180px;
    background: var(--accent3);
    opacity: 0.3;
    left: 38vw;
    top: 18.6vh;
}

.t-content ul li:nth-child(3):after {
    content: '';
    position: absolute;
    height: 10px;
    width: 192px;
    background: var(--accent1);
    opacity: 0.3;
    left: 35vw;
    top: 3.1vh;
} */

.content-inner {
    width: 100vw;
}

.content-inner .quote {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.content-inner .name {
    font-size: 0.9rem;
    letter-spacing: 0.88px;
    line-height: 28px;
    color: var(--lighttext2);
}

.content-inner .title {
    font-size: 0.75rem;
    letter-spacing: 0.88px;
    line-height: 20px;
    color: var(--lighttext2);
}

@media (min-width: 40rem) {
    .testimonial-section {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--lighttext);
        background-color: var(--lightbackground1);
    }

    /* .testimonial-section:after {
    content: '';
    position: absolute;
    width: 900px;
    height: 550px;
    background: var(--lightbackground2);
    right: 0;
    bottom: 0;
    opacity: 0.5;
} */

    .testimonial-container {
        width: 1280px;
        min-width: 1280px;
        height: 600px;
        position: relative;
    }

    .arrows {
        position: absolute;
        display: flex;
        width: 100px;
        align-items: center;
        justify-content: center;
        bottom: 0;
        top: 0;
        cursor: pointer;
        border-radius: 8px;
        transition: 0.3s ease-in-out;
        padding-bottom: 0;
        padding-left: 0;
    }

    .arrows:hover {
        box-shadow: 0px 0px 30px rgba(0, 0, 80, 0.05);
    }

    .arrows.right {
        right: 0;
    }

    .inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 600px;
    }

    .t-image {
        width: 500px;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    }

    /* .t-image:after {
        content: '';
        position: absolute;
        width: 200px;
        height: 200px;
        left: 7.5vw;
        top: 0;
        border-radius: 100%;
        z-index: 10;
    } */

    .color-1:after {
        background: var(--accent2);
        opacity: 0.7;
    }

    .color-2:after {
        background: var(--accent3);
        opacity: 0.7;
    }

    .color-3:after {
        background: var(--accent1);
        opacity: 0.7;
    }

    .t-image ul {
        display: flex;
        position: absolute;
        overflow: hidden;
        height: 460px;
        width: 340px;
        box-shadow: 0px 0px 40px rgba(0, 0, 10, 0.25);
        z-index: 200;
    }

    /* .t-image ul li {
    height: 460px;
    width: 340px;
} */

    /* .t-image ul li img { */

    .projectImage {
        height: 460px;
        width: 340px;
    }

    .t-content {
        width: 500px;
        height: 600px;
        display: flex;
        align-items: center;
    }

    .t-content ul {
        position: absolute;
        overflow: hidden;
        width: 500px;
        height: 400px;
    }

    .t-content ul li {
        width: 500px;
        height: 400px;
        padding: 0;
        top: 0;
        color: var(--lighttext);
        display: flex;
        align-items: center;
        position: absolute;
        opacity: 0;
    }

    .t-content ul li:nth-child(1):after {
        content: '';
        position: absolute;
        height: 12px;
        width: 334px;
        background: var(--accent2);
        opacity: 0.3;
        left: 0px;
        top: 134px;
    }

    .t-content ul li:nth-child(2):after {
        content: '';
        position: absolute;
        height: 12px;
        width: 197px;
        background: var(--accent3);
        opacity: 0.3;
        left: 0px;
        top: 230px;
    }

    .t-content ul li:nth-child(3):after {
        content: '';
        position: absolute;
        height: 12px;
        width: 205px;
        background: var(--accent1);
        opacity: 0.3;
        left: 117px;
        top: 101px;
    }

    .content-inner {
        width: 500px;
    }

    .content-inner .quote {
        font-size: 1.5rem;
        /* letter-spacing: 0.88px; */
        line-height: 2.4rem;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .content-inner .name {
        font-size: 1.2rem;
        letter-spacing: 0.88px;
        line-height: 28px;
        color: var(--lighttext2);
        font-weight: 600;
    }

    .content-inner .title {
        font-size: 0.85rem;
        letter-spacing: 0.88px;
        line-height: 28px;
        color: var(--lighttext2);
    }
}

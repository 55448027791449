@import '../../Vars.css';

.hidden-header {
    position: absolute;
    left: -9999px;
}

.hero-container {
    position: relative;
    min-height: 600px;
    height: 100vh;
    color: var(--text);
    background-image: linear-gradient(150deg, var(--background1), var(--background2));
}

.pos-abs {
    position: absolute;
    top: 0;
    left: 0;
}

.flex-cont {
    font-size: 40px;
    min-height: 500px;
    min-width: 200px;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex-cont-2 {
    min-height: 500px;
    min-width: 200px;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-container2 {
    flex-direction: row;
}

.reveal-container {
    position: relative;
}

.reveal-box {
    z-index: 400000;
    position: absolute;
    opacity: 0;
    width: 100px;
    height: 172px;
    background-color: var(--accent1);
}

.hero-text2 {
    font-size: 70px;
    text-align: center;
    opacity: 0;
}

.mouse-scroll {
    opacity: 0;
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 100px;
    padding-top: 85vh;
}

.m-scroll-arrows {
    display: block;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);

    border-right: 2px solid var(--accent0);
    border-bottom: 2px solid var(--accent0);
    margin: 0 0 3px 4px;

    width: 16px;
    height: 16px;
}

.top-arrow,
.bottom-arrow {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
}

.top-arrow {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -webkit-animation-direction: alternate;

    animation-direction: alternate;
    animation-delay: alternate;
}

.bottom-arrow {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -webkit-animation-direction: alternate;

    animation-delay: 0.2s;
    animation-direction: alternate;

    margin-top: -9px;
}

.mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid var(--accent0);
    top: 170px;
}

.wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: var(--accent0);
    position: relative;

    height: 4px;
    width: 4px;
    border: 2px solid var(--accent0);
    -webkit-border-radius: 8px;
    border-radius: 8px;

    animation-timing-function: ease-in;
}

.wheel {
    -webkit-animation: mouse-wheel 0.6s linear infinite;
    -moz-animation: mouse-wheel 0.6s linear infinite;
    animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
        transform: translateY(6px);
    }
}

@-moz-keyframes mouse-wheel {
    0% {
        top: 1px;
    }
    6.25% {
        top: 1px;
    }
    12.5% {
        top: 2px;
    }
    18.75% {
        top: 2px;
    }
    25% {
        top: 3px;
    }
    31.25% {
        top: 3px;
    }
    37.5% {
        top: 4px;
    }
    43.75% {
        top: 4px;
    }
    50% {
        top: 5px;
    }
    56.25% {
        top: 4px;
    }
    62.5% {
        top: 4px;
    }
    68.75% {
        top: 3px;
    }
    75% {
        top: 3px;
    }
    81.25% {
        top: 2px;
    }
    87.5% {
        top: 2px;
    }
    93.75% {
        top: 1px;
    }
    100% {
        top: 1px;
    }
}

@-o-keyframes mouse-wheel {
    0% {
        top: 1px;
    }
    6.25% {
        top: 1px;
    }
    12.5% {
        top: 2px;
    }
    18.75% {
        top: 2px;
    }
    25% {
        top: 3px;
    }
    31.25% {
        top: 3px;
    }
    37.5% {
        top: 4px;
    }
    43.75% {
        top: 4px;
    }
    50% {
        top: 5px;
    }
    56.25% {
        top: 4px;
    }
    62.5% {
        top: 4px;
    }
    68.75% {
        top: 3px;
    }
    75% {
        top: 3px;
    }
    81.25% {
        top: 2px;
    }
    87.5% {
        top: 2px;
    }
    93.75% {
        top: 1px;
    }
    100% {
        top: 1px;
    }
}
@keyframes mouse-wheel {
    0% {
        top: 1px;
    }
    6.25% {
        top: 1px;
    }
    12.5% {
        top: 2px;
    }
    18.75% {
        top: 2px;
    }
    25% {
        top: 3px;
    }
    31.25% {
        top: 3px;
    }
    37.5% {
        top: 4px;
    }
    43.75% {
        top: 4px;
    }
    50% {
        top: 5px;
    }
    56.25% {
        top: 4px;
    }
    62.5% {
        top: 4px;
    }
    68.75% {
        top: 3px;
    }
    75% {
        top: 3px;
    }
    81.25% {
        top: 2px;
    }
    87.5% {
        top: 2px;
    }
    93.75% {
        top: 1px;
    }
    100% {
        top: 1px;
    }
}

@-webkit-keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 40rem) {
    .hero-container {
        min-height: 1000px;
        height: 100vh;
    }

    .flex-cont {
        font-size: 50px;
        min-height: 500px;
        min-width: 200px;
    }

    .flex-cont-2 {
        font-size: 80px;
        min-height: 500px;
        min-width: 200px;
    }

    .reveal-box {
        width: 100px;
        height: 100px;
    }
}

@import '../../../Vars.css';

.tagList {
    width: 30vw;
    max-width: 90vw;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem 0;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
}

.loopSlider .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
}

.tag {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    color: var(--lighttext);
    font-size: 0.9rem;
    background-color: var(--lightbackground1);
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem;
    box-shadow: 0 0.1rem 0.3rem #2e2e2e, 0 0.1rem 0.3rem #2e2e2e, 0 0.1rem 1rem #2e2e2e;
}

.tag span {
    font-size: 1.2rem;
    color: var(--accent1);
}

.fade {
    pointer-events: none;
    background: linear-gradient(90deg, var(--lightbackground1), transparent 30%, transparent 70%, var(--lightbackground1));
    position: absolute;
    inset: 0;
}

@keyframes loop {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
